@font-face {
    font-family: 'nowayregular';
    src: url('../fonts/noway-regular-webfont.eot');
    src: url('../fonts/noway-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/noway-regular-webfont.woff2') format('woff2'),
         url('../fonts/noway-regular-webfont.woff') format('woff'),
         url('../fonts/noway-regular-webfont.ttf') format('truetype'),
         url('../fonts/noway-regular-webfont.svg#nowayregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'nexa_boldregular';
    src: url('../fonts/Nexa_Free_Bold-webfont.eot');
    src: url('../fonts/Nexa_Free_Bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Nexa_Free_Bold-webfont.woff2') format('woff2'),
         url('../fonts/Nexa_Free_Bold-webfont.woff') format('woff'),
         url('../fonts/Nexa_Free_Bold-webfont.ttf') format('truetype'),
         url('../fonts/Nexa_Free_Bold-webfont.svg#nexa_boldregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'nexa_lightregular';
    src: url('../fonts/Nexa_Free_Light-webfont.eot');
    src: url('../fonts/Nexa_Free_Light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/Nexa_Free_Light-webfont.woff2') format('woff2'),
         url('../fonts/Nexa_Free_Light-webfont.woff') format('woff'),
         url('../fonts/Nexa_Free_Light-webfont.ttf') format('truetype'),
         url('../fonts/Nexa_Free_Light-webfont.svg#nexa_lightregular') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
  font-family: 'fontello';
  src: url('../fonts/fontello.eot?88363053');
  src: url('../fonts/fontello.eot?88363053#iefix') format('embedded-opentype'),
       url('../fonts/fontello.woff2?88363053') format('woff2'),
       url('../fonts/fontello.woff?88363053') format('woff'),
       url('../fonts/fontello.ttf?88363053') format('truetype'),
       url('../fonts/fontello.svg?88363053#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
