//set standard styling thats not set in normalize

* {
	box-sizing: border-box;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html {
	height: 100%;
}

body {
	position: relative;
	height: 100%;
	min-height: 100%;
	font-family: $font-text;
	font-size: $font-size-base;
	line-height: $line-height-base;
	font-weight: 400;
	background: #FFF;
	color: $text-color;
	overflow-x: hidden;
	color: $text-color;
	&.dark {
		background: #000;
		color: #FFF;
	}
}

button,
input,
optgroup,
option,
select,
textarea {
	font-family: $font-text;
	font-size: $font-size-base;
	line-height: $line-height-base;
	font-weight: 400;
	box-shadow: none;
}

dd,
fieldset,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
hgroup,
hr,
ol,
p,
pre,
table,
ul {
	margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $font-text;
	line-height: 1.1;
	font-weight: 400;
}

fieldset {
	margin: 0;
	border: none;
	padding: 0;
}

legend {
	display: block;
	margin: 0;
	padding: 0;
	width: 100%;
	background: none;
}

label,
legend {
	display: block;
	color: $text-color;
}

button {
	background: transparent;
	border: none;
	box-shadow: none;
	outline: none;
	padding: 0;
	margin: 0;
}

input {
	color: $text-color;
}

a {
	color: $link-color;
	text-decoration: underline;

	&:hover {
		text-decoration: none;
	}
}
//hide spinner in webkit

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
//hide spinner in firefox

input[type=number] {
	-moz-appearance: textfield;
}
//hide edge clear icon

input::-ms-clear {
	display: none;
	width: 0;
	height: 0;
}
