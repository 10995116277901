$susy: (
	container: 1200px,
  	columns: 12,
  	gutters: 1/8,
  	gutter-position: inside,
);

.c-slide {
	position: relative;
	width: 100%;
	padding: 60px 0 80px;
	&:first-child {
		padding-top: 30px;
	}

	&__maxwidth {
		@include container;
		padding: 0 $container-padding;
	}
	&__inner {
		position: relative;
		&--single {
			width: 100%;
		}
	}
	&__image {
		margin: 0 auto 30px;
		max-width: 340px;
		img {
			display: block;
			width: 100%;
		}
	}
	&__text {
		&--left {
			margin-bottom: 40px;
		}
		&--right {
			margin-top: 40px;
		}
	}
	&__title {
		font-family: $font-text;
		font-size: 32px;
		margin-bottom: 24px;
		font-weight: 700;
	}
	&__intro {
		font-size: 22px;
		margin: -16px 0 24px;
	}
	&__paragraph {
		margin-bottom: 16px;
		&--button {
			margin-top: 24px;
			margin-bottom: 0;
		}
	}
	&__btn-scroll {
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
	}
	@media screen and (min-width: $screen-xs) {
		display: flex;
		align-items: center;
		min-height: 100vh;
		padding: 40px 0 80px;
		&:first-child {
			padding-top: 40px;
		}
		&__btn-scroll {
			bottom: 10px;
		}

	}
	@media screen and (min-width: $screen-sm) {
		padding: 60px 0;
		&__image {
			max-width: 400px;
		}
		&__text {
			padding: 20px 50px;
		}
		&__btn-scroll {
			bottom: 20px;
		}

	}
	@media screen and (min-width: $screen-md) {
		&__inner {
			&--duo {
				display: flex;
				align-items: center;
			}
		}
		&__image {
			width: span(5);
			flex: 0 0 auto;
			margin: 0;
			max-width: none;
		}
		&__text {
			width: span(6);
			max-width: 540px;
			flex: 1 1 auto;
			padding: 20px 0;
			&--left {
				margin: 0 span(1) 0 0;
			}
			&--right {
				margin: 0 0 0 span(1);
			}
		}
		&__title {
			font-size: 36px;
			margin-bottom: 24px;
		}
		&__intro {
			font-size: 24px;
		}
		&__btn-scroll {
			bottom: 40px;
		}
	}
}
