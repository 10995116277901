[class*=" c-icon-"],
[class^="c-icon-"] {
    line-height: 1em;
    &:before {
        font-family: "fontello";
        font-weight: normal;
        font-style: normal;
        display: inline-block;
        width: 1em;
        height: 1em;
        line-height: 1;
        text-transform: none;
        letter-spacing: normal;
        word-wrap: normal;
        white-space: nowrap;
        direction: ltr;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        font-feature-settings: 'liga';
    }
}

.c-icon-arrow-downward:before {
    content: '\e800';
}
.c-icon-arrow-left:before {
    content: '\e801';
}
.c-icon-arrow-right:before {
    content: '\e802';
}
.c-icon-arrow-up:before {
    content: '\e803';
}
.c-icon-arrow-down:before {
    content: '\e804';
}
.c-icon-home:before {
    content: '\e805';
}
