@import '../../bower_components/susy/sass/susy.scss';
$susy: (
	container: 1200px,
  	columns: 12,
  	gutters: 1/8,
  	gutter-position: inside,
);

.l-container-page {
	@include container;
	padding: 0 $container-padding;
	&--center {
		display: flex;
		flex-direction: column;
		min-height: 100%;
		justify-content: center;
	}
}
.l-main {
	position: relative;
}
