.c-btn-home {
	position: absolute;
	right: 0;
	top: 0;
	display: inline-block;
	padding: 6px;
	background: $turquoise;
	border-radius: 0 0 0 4px;
	z-index: 400;
	&:hover, &:focus {
		background: lighten($turquoise, 10%);
	}
	&__icon {
		display: block;
		width: 24px;
		height: 24px;
	}
	@media screen and (min-width: $screen-sm) {
		padding: 12px;
		&__icon {
			width: 32px;
			height: 32px;
		}
	}
}

.c-btn {
	display: inline-block;
	cursor: pointer;
	text-decoration: none;
	padding: 10px 16px;
	transition: background 0.4s ease;
	@media screen and (min-width: $screen-xs) {
		padding: 10px 24px;
	}
	&__icon {
		&--left {
			margin-right: 6px;
		}
		&--right {
			margin-left: 6px;
		}
	}
	&--primary {
		border: 2px solid $brand-color;
		background: $brand-color;
		color: #FFF;
		&:hover {
			background: lighten(desaturate($brand-color, 50%), 20%);
		}
	}
	&--scroll {
		position: relative;
		display: block;
		width: 54px;
		height: 54px;
		background: $brand-color;
		border: 2px solid $brand-color;
		border-radius: 50%;
		padding: 0;
		color: #FFF;
		.c-btn__icon {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			font-size: 40px;
		}
		&:hover {
			background: lighten(desaturate($brand-color, 50%), 20%);
		}
	}
}
