/***SETTINGS***/
@import "00-settings/fonts";
@import "00-settings/vars";

/***TOOLS***/
@import "01-tools/mixins";
@import "01-tools/helpers";

/***GENERIC***/
@import "02-generic/normalize";
@import "02-generic/overall";

/***BASE***/
@import "03-base/layout";

/**COMPONENTS**/
@import "04-components/icons";
@import "04-components/buttons";
@import "04-components/carousel";

/**HOME**/
@import "04-components/intro-text";
// @import "04-components/gallery";
@import "04-components/slides";
@import "04-components/scrollarrow";

/**PLAYGROUND**/
@import "04-components/grid-photos";
// @import "04-components/grid-example";
// @import "04-components/grid-projects";
// @import "04-components/scroll-animation";
