// font-family:"proxima-nova-soft";
@mixin icon ($color: inherit, $size: inherit) {
	display: inline-block;
	font: {
	family: "icons";
	size: $size;
	weight: 400;
	}
	color: $color;
	font-style: normal;
}
@mixin remove-liststyle {
	margin: 0;
	padding: 0;
	list-style: none;
}
@mixin vertical-align {
	position: absolute;
	top:50%;
	transform:translateY(-50%);
}
@mixin horizontal-align {
	position: absolute;
	left:50%;
	transform:translateX(-50%);
}
@mixin center-align {
	position: absolute;
	top: 50%;
	left:50%;
	transform: translateX(-50%) translateY(-50%);
}
@mixin background-opacity($color, $opacity: 0.3) {
    background: $color;
    background: rgba($color, $opacity);
}

@mixin clearfix {
	&:before,
	&:after {
		content:'';
		display:table;
	}
	&:after {
		clear:both;
	}
}
@mixin hide-text {
    position: absolute;
    clip: rect(0px 0px 0px 0px);
}
@mixin h1 {
	font-size: 50px;
	@media screen and (min-width: $screen-sm) {
		font-size: 80px;
	}
}
@mixin h2 {
	font-size: 40px;
	@media screen and (min-width: $screen-sm) {
		font-size: 60px;
	}
}
@mixin h3 {
	font-size: 30px;
	@media screen and (min-width: $screen-sm) {
		font-size: 40px;
	}
}
@mixin h4 {
	font-size: 26px;
	@media screen and (min-width: $screen-sm) {
		font-size: 32px;
	}
}
