.c-grid-photos {
    margin-bottom: 40px;
    &__item {
    }
    &__innergrid {
        .c-grid-photos__image {
            padding-bottom: 56%;
        }
    }
    &__image-container {
        position: relative;
    }
    &__image {
        padding-bottom: 125%;
        margin-bottom: 10px;
        background: no-repeat center center / cover;
        opacity: 1;
        transition: all 1.0s ease;
        .c-grid-photos__image-container--horizontal & {
            padding-bottom: 56%;
        }
        .c-grid-photos__image-container--vertical & {
            padding-bottom: 125%;
        }
        .c-grid-photos__image-container--square & {
            padding-bottom: 100%;
        }
    }
    @media screen and (min-width: $screen-sm) {
        display: flex;
        flex-wrap: wrap;
        margin: -4px -4px 36px;
        &__item {
            padding: 4px;
            //create susy grid modifiers
            @for $i from 1 through 12 {
                &--width-#{$i} {
                    width: span($i);
                }
            }
        }
        &__innergrid {
            display: flex;
            flex-direction: column;
            height: calc(100% + 8px);
            margin: -4px;

            .c-grid-photos__item {
                flex: 1 1 auto;
            }
            .c-grid-photos__image {
                padding-bottom: 0; //let flex handle height
            }
        }
        &__image-container {
            height: 100%;
            &--horizontal {
                padding-bottom: 56%;
            }
            &--vertical {
                padding-bottom: 125%;
            }
            &--square {
                padding-bottom: 100%;
            }
        }
        &__image {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin-bottom: 0;
            padding-bottom: 0;
            .c-grid-photos__image-container--horizontal & {
                padding-bottom: 0;
            }
            .c-grid-photos__image-container--vertical & {
                padding-bottom: 0;
            }
            .c-grid-photos__image-container--square & {
                padding-bottom: 0;
            }
            &.is-hidden {
                opacity: 0;
            }
            .c-grid-photos__image-container:hover & {
                &--overlay {
                    opacity: 0;

                    &.is-hidden {
                        opacity: 1;
                    }
                }
            }
        }
    }
}
