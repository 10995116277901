/***COLORS***/
$grey-darker: #333333;
$grey-dark: #666666;
$grey-medium: #808080;
$grey-light: #dddddd;
$grey-lighter: #F1F1F1;
$turquoise: rgb(8, 159, 163);
$purple: #8a0057;
$yellow: rgb(255, 207, 0);
$green: rgb(94, 177, 1);
$orange: rgb(255, 153, 0);
/***SET COLORS***/
//MAIN
$text-color: $grey-darker;
$link-color: $turquoise;
$hover-color: #D1D7D9;
$brand-color: $turquoise;
/***SIZING***/
//CONTAINER
$container-width: 1200px;
$container-padding: 20px;
//BORDER
$border-radius-base: 4px;
$border-radius-sm: $border-radius-base * 0.75; //3px
$border-radius-xs: $border-radius-base * 0.5; //2px
$border-radius-lg: $border-radius-base * 1.5; //6px
$border-radius-xl: $border-radius-base * 2.0; //8px
/***TYPOGRAPHY***/
$font-text: "nowayregular", sans-serif;
$font-headings: "nexa_boldregular", sans-serif;
$font-size-base: 18px;
$font-size-sm: 16px;
$font-size-lg: 20px;
$line-height-base: 1.4;
/***MEDIA QUERIES***/
$screen-xs: 520px;
$screen-sm: 768px;
$screen-md: 1024px;
$screen-lg: 1200px;
$screen-xl: 1400px;
