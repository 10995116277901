//MAIN STYLING CAROUSEL
.owl-carousel {
	.owl-stage {
		display: flex;
	}
	.owl-item {
		flex: 0 0 auto;
	}
	.owl-nav {
		.owl-prev,
		.owl-next {
			position: absolute;
			top: 50%;
			transition: opacity 0.4s ease;
			font-size: 40px;
			width: 30px;
			height: 50px;
			background: rgba(#FFF,0.8);
			color: $turquoise;
			&:hover {
				background: rgba(#FFF,1);
			}
			[class*="icon"] {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
			}
			&.disabled {
				opacity: 0;
			}
		}
		.owl-prev {
			left: -$container-padding;;
			transform: translateY(-50%);
			border-radius: 0 4px 4px 0;
		}
		.owl-next {
			right: -$container-padding;;
			transform: translateY(-50%);
			border-radius: 4px 0 0 4px;
		}
	}
	@media screen and (min-width: $screen-xs) {
		.owl-nav {
			.owl-prev,
			.owl-next {
				font-size: 60px;
				width: 40px;
				height: 60px;
			}
		}
	}
	@media screen and (min-width: $screen-sm) {
		.owl-nav {
			.owl-prev {
				left: 0;
			}
			.owl-next {
				right: 0;
			}
		}
	}
	@media screen and (min-width: $screen-xl) {
		.owl-nav {
			.owl-prev {
				left: 0;
				transform: translate(-200%, -50%);
			}
		}
	}
}
//cAROUSEL PROJECTS
.c-carousel-projects {
	width: 100%;
	margin-top: 60px;;
	padding: 0 $container-padding;
	&__item {
		position: relative;
		&--description {
			position: relative;
			height: 100%;
		}
		&--image {
			margin-bottom: 12px;
		}
		&--break {
			margin-bottom: 40px;
		}
	}
	&__image {
		display: block;
		width: 100%;
	}
	&__description {
		border-top: 2px solid $grey-light;
		padding: 30px 0;
	}
	&__title {
		font-size: 26px;
		margin-bottom: 4px;
	}
	&__company {
		font-size: 16px;
		margin-bottom: 24px;
		color: $grey-dark;
	}
	&__paragraph {
		margin-bottom: 12px;
	}
	&__note {
		font-size: 16px;
		color: $grey-dark;
		font-style: italic;
	}
	@media screen and (min-width: $screen-xs) {

	}
	@media screen and (max-width: $screen-sm - 1px) {
		.owl-carousel {
			display: block;
		}
	}
	@media screen and (min-width: $screen-sm) {
		padding: 0 0 0 $container-padding;
		&__item {
			width: auto;
			height: 380px;
			&--description {
				width: 650px;
			}
			&--image {
				margin-bottom: 0;
				margin-right: 20px;
			}
			&--break {
				margin-bottom: 0;
				margin-right: 100px;
				&:after {
					position: absolute;
					right: -40px;
					top: 4%;
					bottom: 4%;
					width: 4px;
					background: $purple;
					content: "";
					border-radius: 2px;
				}
			}
		}
		&__image {
			width: auto !important;
			height: 100%;
		}
		&__description {
			height: 100%;
			padding: 34px 26px 34px 40px;
			margin-right: 40px;
			border-bottom: 2px solid $grey-light;
		}
	}
	@media screen and (min-width: $screen-md) {
		&__item {
			height: 450px;
		}
		&__description {
			padding: 60px 30px 60px 50px;
		}
		&__title {
			font-size: 30px;
		}
	}
	@media screen and (min-width: $screen-lg) {
		padding-left: calc(((100% - #{container()}) / 2) + #{$container-padding}) ; //start at same position as container
		&__description {
			padding: 60px 30px 60px 40px;
		}
	}
}
//NAV PROJECTS
.c-carousel-projects-nav {
	display: none;
	@media screen and (min-width: $screen-sm) {
		@include remove-liststyle;
		display: flex;
		flex-wrap:wrap;
		margin-top: 30px;
		align-items: center;
		margin: 26px -12px 0;
		&__item {
			flex: 0 0 auto;
			position: relative;
			padding: 4px 12px;
			&:after {
				position: absolute;
				top: 50%;
				left: 100%;
				transform: translate(-50%,-50%);
				width: 6px;
				height: 6px;
				border-radius: 50%;
				background: $purple;
				content: "";
			}
			&:last-child {
				&:after {
					display: none;
				}
			}
		}
		&__link {
			display: block;
			text-decoration: none;
			font-size: 14px;
			color: $purple;
			&.is-active {
				font-size: 20px;
				font-weight: 700;
			}
		}
	}
}
//CAROUSEL PHOTOS
.c-carousel-photos {
	width: 100%;
	margin-top: 60px;;
	padding: 0 $container-padding;
	&__item {
		height: 100%;
		background: #000;
		display: flex;
		align-items: center;
	}
	&__image {
		display: block;
		width: auto !important;
		max-width: 100%;
		max-height: 450px;
		margin: 0 auto;
	}
	@media screen and (min-width: $screen-sm) {
		&__item {
			display: block;
			width: auto;
			height: 380px;
			background: none;
			max-height: none;
			margin: 0 10px 0 0;
		}
		&__image {
			width: auto;
			height: 100%;
			max-width: none;
			max-height: none;
			margin: 0;
		}

	}
	@media screen and (min-width: $screen-md) {
		&__item {
			height: 480px;
		}
	}
	@media screen and (min-width: $screen-lg) {
		padding-left: calc(((100% - #{container()}) / 2) + #{$container-padding}) ; //start at same position as container
	}
}
