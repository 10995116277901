.c-scrollarrow {
	position: fixed;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	padding: 6px;
	background: rgba($grey-light, 0.6);
	border-radius: 10px 10px 0 0;
	cursor: pointer;
	&:hover {
		background: rgba($grey-light, 0.8);
		.c-scrollarrow__icon {
			color: $grey-dark;
		}
	}
	&__icon {
		display: block;
		font-size: 30px;
		color: $grey-medium;
	}
}
