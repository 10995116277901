.h-hidden {
	display: none !important;
}
.h-hidden-xs-max {
	@media screen and (max-width: ($screen-xs - 1px)) {
		display: none !important;
	}
}
.h-hidden-sm-max {
	@media screen and (max-width: ($screen-sm - 1px)) {
		display: none !important;
	}
}
.h-hidden-xs-min {
	@media screen and (min-width: $screen-xs) {
		display: none !important;
	}
}
.h-hidden-sm-min {
	@media screen and (min-width: $screen-sm) {
		display: none !important;
	}
}
.h-hidden-md-min {
	@media screen and (min-width: $screen-lg) {
		display: none !important;
	}
}
.h-sr-only {
	@include hide-text;
}
